@import "../../../scss/variable.scss";
@import "../../../scss/mixin.scss";

// .accordion-root-sidenavbar {
//   background-color: transparent !important;
//   box-shadow: none !important;
//   position: static !important;
//   margin-bottom: 0px !important;
// }

// .accordion-root-sidenavbar.Mui-expanded {
//   margin: 0px !important;
//   margin-bottom: 0px !important;
// }

// .side-navbar-root,
// .side-navbar-root > div.MuiBackdrop-root {
//   position: relative !important;
// }

// .side-navbar {
//   width: 265px;
//   z-index: 0 !important;
//   height: calc(100% - 70px) !important;
//   background: $secondary_grey 0% 0% no-repeat padding-box;
//   top: 70px !important;
//   padding-top: 10px;
//   padding-bottom: 20px;
//   transition: 0.5s ease !important;
//   // @include outset;
//   @include firefox-scroll;
//   // animation: shrink2 0.3s linear;
//   pointer-events: auto !important; //prevents disabling user interaction when ongoing api requests
//   .side-navbar-link {
//     width: calc(100% - 15px);
//     height: 49px;
//     min-height: 30px !important;
//     margin-left: auto !important;
//     margin-right: auto !important;
//     padding: 8px 6px 8px 20px;
//     border-radius: 40px;
//     font-size: 14px;
//     font-family: $Inter;
//     font-weight: 500;

//     &:hover {
//       cursor: pointer;
//     }

//     img {
//       width: 16px;
//       height: 16px;
//       margin-right: 5px;
//     }

//     .MuiIconButton-label {
//       img {
//         margin-bottom: -10px;
//       }
//     }

//     label {
//       transform: translateY(1px);

//       &:hover {
//         cursor: pointer;
//       }
//     }
//   }

//   .side-navbar-link.inactive-link {
//     background: #ecf0f3;
//     color: $primary_link;

//     &:hover {
//       @include inset;
//     }
//   }

//   .side-navbar-link.sub-inactive-link {
//     color: $primary_link;
//   }

//   .side-navbar-link.active-link {
//     @include inset;
//     color: $primary_text;
//   }

//   .side-navbar-link.sub-active-link {
//     color: $primary_text;
//   }
// }

// .collapse-side-navbar {
//   width: 91px;
//   overflow-x: hidden;
// }

// .slider {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 51px;
//   width: 0px;
//   height: 50px;
//   background: #eebb5c;
//   border-radius: 3px;
//   transition: all 0.5s 0.2s ease;
// }

// @keyframes shrink {
//   0% {
//     width: 275px;
//   }

//   50% {
//     width: 150px;
//   }

//   100% {
//     width: 80px;
//   }
// }

// @keyframes shrink2 {
//   0% {
//     width: 80px;
//   }

//   50% {
//     width: 150px;
//   }

//   100% {
//     width: 275px;
//   }
// }

// .collapse-side-navbar img {
//   margin-left: 0;
// }

// .side-navbar-link-collapse {
//   width: auto;
//   height: auto;
//   margin-left: auto !important;
//   margin-right: auto !important;
//   padding: 0px 10px;
//   border-radius: 40px !important;
//   font-size: 14px;
//   font-family: $Inter;
//   font-weight: 500;
//   text-align: center;

//   &:hover {
//     cursor: pointer;
//   }

//   img {
//     width: 16px;
//     height: 16px;
//     margin-left: 0px;
//   }

//   label {
//     transform: translateY(1px);

//     &:hover {
//       cursor: pointer;
//     }
//   }
// }

// .side-navbar-link-collapse.inactive-link {
//   color: $primary_link;

//   &:hover {
//     @include inset;
//   }
// }

// .side-navbar-link-collapse.sub-inactive-link {
//   color: $primary_link;
//   position: relative !important;
// }

// .side-navbar-link-collapse.sub-active-link {
//   color: $primary_text;
//   position: relative !important;
// }

// .collapsible-navbar-title {
//   display: none;
//   position: absolute;
//   top: 14px;
//   text-align: left;
//   left: 40px;
//   font-size: 13px;
// }

// .collapsible-navbar-title2 {
//   display: none;
//   position: absolute;
//   top: -1px;
//   text-align: left;
//   left: 40px;
//   font-size: 13px;
// }

// .side-navbar-link-collapse.inactive-link:hover .collapsible-navbar-title,
// .side-navbar-link-collapse.active-link:hover .collapsible-navbar-title {
//   z-index: 1200;
//   display: contents;
//   font-size: 12px;
// }

// .side-navbar-link-collapse.sub-inactive-link:hover {
//   @include inset;
//   min-height: 40px !important;
// }

// .side-navbar-link-collapse.sub-active-link:hover {
//   @include inset;
//   min-height: 40px !important;
// }

// .side-navbar-link-collapse.sub-inactive-link:hover .collapsible-navbar-title2,
// .side-navbar-link-collapse.sub-active-link:hover .collapsible-navbar-title2 {
//   display: contents;
//   font-size: 12px;
//   z-index: 1200;
// }

// .side-navbar-link-collapse.sub-inactive-link .collapsible-navbar-title2 {
//   margin-top: 10px;
// }

// .side-navbar-link-collapse.active-link:hover {
//   @include inset;
//   color: $primary_text;
// }

// .side-navbar-link-collapse.sub-active-link:hover {
//   margin-top: -1px !important;
// }

// .side-navbar-link-collapse {
//   min-height: 35px !important;
// }

// .side-navbar-link-collapse.sub-inactive-link img {
//   float: left !important;
//   margin-top: 10px;
// }

// .side-navbar-link-collapse.sub-active-link img {
//   float: left !important;
//   margin-top: 10px;
// }

// .side-navbar-link-collapse.sub-active-link .collapsible-navbar-title2 {
//   margin-top: 10px;
// }

// .side-navbar-link-collapse.active-link .collapsible-navbar-title {
//   margin-top: -2px;
// }

// @media only screen and (max-width: 1200px) {
//   .side-navbar .side-navbar-link {
//     font-size: 13px;
//   }
// }

// .side-navbar-link-collapse:hover .sidebar-anchor img {
//   display: block;
//   margin: 0 auto;
// }

// .side-navbar-link-collapse {
//   border-radius: 12px !important;
// }

// .side-navbar-link-collapse.sub-inactive-link:hover img {
//   float: none !important;
// }

// .side-navbar-link-collapse.sub-active-link:hover img {
//   float: none !important;
// }

// .MuiIconButton-edgeEnd {
//   margin-right: 10px !important;
//   margin-top: -5px !important;
// }

// .side-navbar-link-collapse .MuiIconButton-root {
//   padding: 0 !important;
//   margin-top: 1px !important;
//   margin-right: -10px !important;
// }

// .sidebar-link--text {
//   position: absolute;
//   white-space: nowrap;
// }

// .MuiAccordionSummary-expandIcon.Mui-expanded img {
//   margin-bottom: 5px !important;
// }

////////////////
///
///
/// @import "../../../scss/variable.scss";
// @import "../../../scss/mixin.scss";

.accordion-root-sidenavbar {
  background-color: transparent !important;
  box-shadow: none !important;
  position: static !important;
  margin-bottom: 0px !important;
}

.accordion-root-sidenavbar.Mui-expanded {
  margin: 0px !important;
  margin-bottom: 0px !important;
}

.side-navbar-root,
.side-navbar-root > div.MuiBackdrop-root {
  position: relative !important;
  overflow-y: none;
  // z-index: 1111 !important;
}

.paper-overflow {
  overflow-y: visible !important;
}

.side-navbar {
  font-family: $Inter !important;
  // z-index: 111111 !important;
  width: 240px;
  // gap: 0.rem;
  height: calc(100% - 60.3px) !important;
  // background-color: #ecf0f3;
  // background: #E5EDF3 0% 0% no-repeat padding-box;
  background: #e5edf3 0% 0% no-repeat padding-box !important;
  background-color: #e5edf3;
  top: 60.3px !important;
  padding-top: 10px;
  padding-bottom: 20px;
  // transition: 0.5s ease !important;
  // @include outset;
  @include firefox-scroll;
  // animation: shrink2 0.3s linear;
  pointer-events: auto !important; //prevents disabling user interaction when ongoing api requests
  .side-navbar-link {
    width: calc(100% - 15px);
    height: 49px;
    min-height: 30px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 8px 6px 8px 20px;
    // border-radius: 40px;
    font-size: 14px;
    font-family: $Inter !important;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    .MuiIconButton-label {
      img {
        margin-bottom: -10px;
      }
    }

    label {
      transform: translateY(1px);

      &:hover {
        cursor: pointer;
      }
    }
  }

  .side-navbar-link.inactive-link {
    background: #ffffff;
    color: $primary_link;

    &:hover {
      background-color: #ffffff;
      border-left: 5px solid #5b5fc7;
    }
  }

  .side-navbar-link.sub-inactive-link {
    background-color: #ffffff;
  }

  .side-navbar-link.active-link {
    background-color: #ffffff;
    border-left: 5px solid #5b5fc7;
    color: $primary_text;
  }

  .side-navbar-link.sub-active-link {
    color: $primary_text;
    background-color: #ffffff;
    border-right: 2px solid #5b5fc7;
  }
}

.collapse-side-navbar {
  width: 80px;
  // width: 60px;
  // overflow-x: visible;
}

.slider {
  content: "";
  position: absolute;
  top: 0;
  left: 51px;
  width: 0px;
  height: 50px;
  background: #eebb5c;
  border-radius: 3px;
  transition: all 0.5s 0.2s ease;
}

@keyframes shrink {
  0% {
    width: 275px;
  }

  50% {
    width: 150px;
  }

  100% {
    width: 80px;
  }
}

@keyframes shrink2 {
  0% {
    width: 80px;
  }

  50% {
    width: 150px;
  }

  100% {
    width: 275px;
  }
}

.collapse-side-navbar img {
  margin-left: 0;
}

.side-navbar-link-collapse {
  width: auto;
  height: auto;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 0px 10px;
  font-size: 14px;
  font-family: $Inter;
  font-weight: 500;
  text-align: center;

  &:hover {
    cursor: pointer;
    background-color: #ffffff;
    border-right: 2px solid #5b5fc7;
  }

  img {
    width: 16px;
    height: 16px;
    margin-left: 0px;
  }

  label {
    transform: translateY(1px);

    &:hover {
      cursor: pointer;
    }
  }
}

.side-navbar-link-collapse.inactive-link {
  color: $primary_link;

  // &:hover {
  //   // @include inset;
  //   background-color: #ffffff;
  //   border-right: 2px solid #5B5FC7;
  // }
}
side-navbar-link-collapse.inactive-link:hover .dropdown-content {
  display: inline;
}

.side-navbar-link-collapse.active-link {
  color: $primary_link;
  background-color: #ffffff;
  border-right: 2px solid #5b5fc7;
  &:hover {
    // @include inset;
    background-color: #ffffff;
    border-right: 2px solid #5b5fc7;
  }
}

.side-navbar-link-collapse.sub-inactive-link {
  color: $primary_link;
  position: relative !important;
  // display: none;
}

// .side-navbar-link-collapse :hover .side-navbar-link-collapse.sub-inactive-link {
//   display: inline;
// }

.side-navbar-link-collapse.sub-active-link {
  // color: $primary_text;
  position: relative !important;
  // display: none;
}

.collapsible-navbar-title {
  display: none;
  position: absolute;
  top: 14px;
  text-align: left;
  left: 40px;
  font-size: 13px;
}

.collapsible-navbar-title2 {
  display: none;
  position: absolute;
  top: -1px;
  text-align: left;
  left: 40px;
  font-size: 13px;
}

.side-navbar-link-collapse.inactive-link:hover .collapsible-navbar-title,
.side-navbar-link-collapse.active-link:hover .collapsible-navbar-title {
  display: none;
  z-index: 1200;

  font-size: 12px;
}

.side-navbar-link-collapse.sub-inactive-link:hover {
  // @include inset;
  min-height: 40px !important;
}

.side-navbar-link-collapse.sub-active-link:hover {
  // @include inset;
  min-height: 40px !important;
  display: none;
}

.side-navbar-link-collapse.sub-inactive-link:hover .collapsible-navbar-title2,
.side-navbar-link-collapse.sub-active-link:hover .collapsible-navbar-title2 {
  // display: contents;
  font-size: 12px;
  z-index: 1200;
  display: none;
}

.side-navbar-link-collapse.sub-inactive-link .collapsible-navbar-title2 {
  margin-top: 10px;
}

.side-navbar-link-collapse.active-link:hover {
  // @include inset;
  color: $primary_text;
}

.side-navbar-link-collapse.sub-active-link:hover {
  margin-top: -1px !important;
}

.side-navbar-link-collapse {
  min-height: 35px !important;
}

.side-navbar-link-collapse.sub-inactive-link img {
  float: left !important;
  margin-top: 10px;
}

.side-navbar-link-collapse.sub-active-link img {
  float: left !important;
  margin-top: 10px;
}

.side-navbar-link-collapse.sub-active-link .collapsible-navbar-title2 {
  margin-top: 10px;
}

.side-navbar-link-collapse.active-link .collapsible-navbar-title {
  margin-top: -2px;
}

@media only screen and (max-width: 1200px) {
  .side-navbar .side-navbar-link {
    font-size: 13px;
  }
}

.side-navbar-link-collapse:hover .sidebar-anchor img {
  display: block;
  margin: 0 auto;
}

// .side-navbar-link-collapse {
//   border-radius: 12px !important;
// }

.side-navbar-link-collapse.sub-inactive-link:hover img {
  float: none !important;
}

.side-navbar-link-collapse.sub-active-link:hover img {
  float: none !important;
}

.MuiIconButton-edgeEnd {
  margin-right: 10px !important;
  margin-top: -5px !important;
}

.side-navbar-link-collapse .MuiIconButton-root {
  padding: 0 !important;
  margin-top: 1px !important;
  margin-right: -10px !important;
}

.sidebar-link--text {
  position: absolute;
  white-space: nowrap;
}

.MuiAccordionSummary-expandIcon.Mui-expanded img {
  margin-bottom: 5px !important;
}

// Redesign
.logout-collapse-box {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  .logout-open-close-icon {
    width: 15px;
    height: 15px;
  }
}

.sidenav-content {
  position: relative;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    // background-color: #ffe0e1;
    // border-right: 4px solid #5B5FC7;
    background-color: #ffffff;
    border-left: 5px solid #5b5fc7;
  }
  &.active-bar {
    // background-color: #FFFBFF;
    background-color: #ffffff;
    border-left: 5px solid #5b5fc7;
  }
}
.collapsed-sidebar {
  padding: 10% 0px;
  text-align: center;
}
.open-sidebar {
  padding: 3px 13px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  gap: 16px;

  .arrow-icon {
    float: right;
    position: absolute;
    right: 0;
  }
}
.sidenav-dropdown-content {
  display: none;
  border: 1px solid $sidenavBorder;
  border-radius: 5px;
  width: 220px;
  position: absolute;
  top: 0;
  background-color: $white;
  transition: all 0.2s;
  &:hover {
    display: block;
  }
  &.collapse-left-position {
    // left: 91px;
    left: 74px;
    // position: absolute;
    // z-index: 111111111;
  }
  &.open-left-position {
    left: 250px;
  }
  .sidenav-dropdown-title {
    border-bottom: 1px solid $sidenavBorder;
    padding: 7px;
    font-size: 14px;
    cursor: pointer;
    &.active-sub-title {
      color: $sidenavRed;
    }
  }
}
.sidenav-dropdown-title:last-child {
  border-bottom: none;
}

.sidenav-content:hover .sidenav-dropdown-content {
  display: block;
}
