@import "../../../scss/variable.scss";

.custom-table {
  width: 100%;
  margin-top: 5px;
  font-family: $Inter !important;
  transition: linear;
  transition-duration: 1s;

  .custom-table-head {
    background-color: $secondary_grey;
    border-radius: 5px;

    th {
      color: $title_black;
      font-size: 14px;
      font-weight: 600;
      font-family: $Inter;
      position: relative;
      padding: 8px;
    }
  }

  .tooltip-row {
    background-color: #eaeaea;

    td {
      color: #707070;
    }
  }

  td {
    text-transform: capitalize;
    white-space: nowrap;
    font-family: $Inter;
    font-weight: $fnt500;
    padding: 8px;
  }
}

.product-custom-table .tooltip-row {
  background: #fff1d47a !important;
  cursor: auto;

  td {
    color: inherit;
  }
}

.custom-table td.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
  position: relative;
}

.parent-row {
  background: #f5f5f5;
  text-transform: uppercase !important;
  font-weight: $fnt600 !important;
}

.filter {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 100%;
  padding: 0px 5px;
  cursor: pointer;
  transition: 0.5s ease;

  &:hover {
    background: #fafafa;
  }
}

.table-responsive {
  overflow-x: auto;
}

@media only screen and (max-width: 1330px) {
  .custom-table {
    .custom-table-head {
      th {
        font-size: 12px !important;
      }
    }

    td {
      font-size: 12px !important;
    }
  }
  .custom-table td {
    max-width: 250px !important;
    text-overflow: ellipsis !important;
  }
  .custom-table td.imp {
    max-width: 80px !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
  }

  .product-name-container .featured {
    position: relative;
    top: -2vh;

    &::before {
      transform: translate(8px, 9px) !important;
    }
  }

  .table-address {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.load-table ul {
  margin: 0;
  padding: 0;
}

.load-table ul li {
  display: inline-block;
  margin-right: 5px;
}

.load-table ul li:nth-child(2) {
  animation: firstMove 2s infinite;
}

.load-table ul li:nth-child(3) {
  animation: firstMove 2s infinite;
  animation-delay: 0.5s;
}

.load-table ul li:nth-child(4) {
  animation: firstMove 2s infinite;
  animation-delay: 1s;
}

@keyframes firstMove {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.custom-table td.ellipsis .featured {
  position: absolute;
  left: 100px;
  top: 12px;
  width: 53px;
  height: 17px;

  &::before {
    transform: translate(8px, 10px);
  }
}
