@import "../../../scss/variable.scss";

.logo-container {
  background-color: $white;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 10px 0px;
  img {
    animation: first 0.5s linear;
    width: 100%;
    height: 60px;
  }
}
.left-margin img {
  margin-left: -20px;
}
@keyframes first {
  0% {
    width: 275px;
  }
  50% {
    width: 150px;
  }
  100% {
    width: 100px;
  }
}
