.app-crashed {
  display: grid;
  place-items: center;
  padding: 2em;
  background: white;
  height: 100%;
}

.app-crash__image {
  width: 25%;
  img {
    max-width: 100%;
  }
}

.app-crash__description {
  font-family: "Poppins";
  display: flex;
  align-items: center;
  flex-direction: column;

  > h2 {
    margin-top: 20px;
    color: #f95a3f;
  }

  > p {
    font-size: 15px;
  }

  > button {
    background: #f95a3f;
    border: none;
    font-size: 14px;
    color: white;
    padding: 10px 2em;
    border-radius: 1em;
  }
}
