@mixin outset {
  -webkit-box-shadow: -3px -3px 7px #ffffffb2,
    3px 3px 5px rgba(94, 104, 121, 0.42) !important;
  box-shadow: -3px -3px 7px #ffffffb2, 3px 3px 5px rgba(94, 104, 121, 0.42) !important;
  background: $secondary_grey !important;
}

@mixin inset {
  -webkit-box-shadow: inset -3px -3px 7px #ffffffb0,
    inset 3px 3px 5px rgba(94, 104, 121, 0.692) !important;
  box-shadow: inset -3px -3px 7px #ffffffb0,
    inset 3px 3px 5px rgba(94, 104, 121, 0.692) !important;
  background: $secondary_grey !important;
}

@mixin text-muted {
  color: $disabled_grey;
  font-size: 0.8rem;
}

@mixin firefox-scroll {
  scrollbar-width: thin;
}
