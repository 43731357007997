@import "../../../../scss/variable.scss";

$parentClass: "config-app-details";

.#{$parentClass} {
  font-size: 1rem;
  .#{$parentClass + "__container"} {
    padding: 2.5em;
  }
  .#{$parentClass + "__text"} {
    margin-bottom: 1em;
  }

  //Password text wrapper.
  .#{$parentClass + "__password-wrapper"} {
    margin-bottom: 1em;
    border: 1px solid grey;
    width: 13em;
    padding: 0.5em 1em;
    border-radius: 5px;
    margin: 0 auto;
    background: $light_grey;
  }

  //Password of the guest user.
  .#{$parentClass + "__password-text"} {
    color: $primary_text;
  }
}
