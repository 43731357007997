@import "../../../../scss/variable.scss";
@import "../../../../scss/mixin.scss";

$className: "form-container";
$parentClass: "feedback-form";

.#{$parentClass}:not(.form-submitted) {
  font-size: 1rem;
  max-width: unset !important;
  width: 800px;

  .#{$className} {
    padding: 1em;
  }
  .#{$className + "__screenshot-picker"} {
    position: relative;
    margin-top: 1rem;
  }

  .#{$className + "__text-muted"} {
    @include text-muted;
  }

  .#{$className + "__screenshot-remover"} {
    position: absolute;
    height: 2em;
    width: 2em;
    right: 2em;
    top: 0.5em;
  }
}
