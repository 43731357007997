@import "../../../scss/variable.scss";

.disabled-btn {
  color: $disabled_grey !important;
}

// .custom-btn:hover {
//   background: linear-gradient(269.86deg, #34576E 0.11%, rgba(59, 127, 171, 0.98) 99.88%);
//   ;
// }

.center-button {
  justify-content: center;
}

.money-text-design {
  font-weight: 600;
}
