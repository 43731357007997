// .action-icon {
//   width: auto;
//   height: auto;
//   padding: 0px !important;

//   &:focus {
//     outline: none;
//     border: 0px;
//   }

//   img {
//     width: 15px;
//     height: 15px;
//   }
// }

.action-icon {
  width: auto;
  height: auto;
  padding: 0px !important;

  &:focus {
    outline: none;
    border: 0px;
  }

  img {
    width: 15px;
    height: 15px;
  }
}

.refund-action-icon {
  width: auto;
  height: auto;
  padding: 0px !important;

  &:focus {
    outline: none;
    border: 0px;
  }

  img {
    width: 15px;
    height: 15px;
  }
}

.popup-icon {
  width: auto;
  height: auto;
  padding: 0px !important;

  &:focus {
    outline: none;
    border: 0px;
  }

  img {
    margin-top: 5px;
    width: 15px;
    height: 15px;
  }
}
.popup-icon-filter {
  width: auto;
  height: auto;
  // padding: 0px !important;
  float: right !important;

  &:focus {
    outline: none;
    border: 0px;
  }

  img {
    margin-top: 1px;
    width: 16px;
    height: 16px;
  }
}

.rotate-center {
  -webkit-animation: rotate-center 0.6s ease-in-out both;
  animation: rotate-center 0.6s ease-in-out both;
}

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
