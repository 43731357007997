@import "../../../scss/variable.scss";

.switch-form-control-label {
  color: #707070;
  margin-left: 0px !important;
  align-items: flex-start;
}

.custom-switch-root {
  width: 80px !important;
  height: 55px !important;
  padding-left: 0px !important;
  padding-top: 4px !important;
}

.custom-switch-track {
  background-color: $white !important;
  border: 1px solid #707070;
  border-radius: 26px !important;
}

.custom-switch-thumb {
  width: 30px !important;
  height: 30px !important;
  transform: translate(-5px, -1px);
}

.custom-switch-base {
  color: #dadada !important;
}

.custom-switch-base.Mui-checked {
  transform: translateX(30px) !important;
  color: $editBtnClr !important;
}

.custom-switch-base.Mui-checked + .custom-switch-track {
  background-color: #58a159 !important;
  border: 1px solid #58a159;
}
