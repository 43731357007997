@import "../../../scss/variable.scss";

.filters-accordian-summary {
  width: 100%;
  border: 1px solid $primary_link !important;
  border-radius: 5px !important;

  &:before {
    background-color: transparent;
  }
}

.filters-expand-icon img {
  width: 28px;
  height: 20px;
}
