@import "../../../scss/variable.scss";
@import "../../../scss/mixin.scss";

.card-container {
  font-family: $Inter !important;
  font-weight: normal !important;
  width: calc(100% - 16px);
  margin-inline: auto;
  margin-top: 16px;
  border-radius: 0px !important;
  margin-bottom: 25px;
  // width: calc(100% - 75px);
  //  margin: auto;
  box-shadow: 1px 1px 17px 0px rgba(0, 0, 0, 0.2) !important;

  .left-arrow {
    width: 31px;
    height: 28px;
    transform: translateY(-1px);
    cursor: pointer;
  }

  .card-brand {
    color: $title_black;
    font-size: 23px;
    font-family: $Inter;
    font-weight: 500;
    transform: translateY(4px);
    text-transform: capitalize;
  }

  .add-btn {
    color: $white;
    background: linear-gradient(
      269.86deg,
      #34576e 0.11%,
      rgba(59, 127, 171, 0.98) 99.88%
    );
    .add-icon {
      width: 16px;
      height: 16px;
      transform: translateY(1px);
      margin-right: 4px;
    }

    &:hover {
      background: linear-gradient(
        269.86deg,
        #5e87a3 0.11%,
        rgba(59, 127, 171, 0.98) 99.88%
      );
    }
  }

  .add-btn-transparent {
    color: #43708e;
    box-shadow: 2px 2px 2px;
    .add-icon {
      width: 16px;
      height: 16px;
      transform: translateY(1px);
      margin-right: 4px;
    }

    &:hover {
      color: $white;
      background: linear-gradient(
        269.86deg,
        #5e87a3 0.11%,
        rgba(59, 127, 171, 0.98) 99.88%
      );

      box-shadow: 3px 3px 3px grey;
    }
  }

  .bulk-btn-10 {
    margin-bottom: 10px;
  }

  .cardContainer-btn {
    display: flex;
    justify-content: flex-end;
    width: auto;
    gap: 5px;
  }
}

@media only screen and (max-width: 1330px) {
  .card-container .card-brand {
    font-size: 20px;
    transform: translateY(9px);
  }

  .card-container .add-btn {
    margin-bottom: 10px;
  }
}
