@import "../../../scss/variable.scss";
.license-expired-modal {
  padding: 1em;
  text-align: center;
  max-width: 550px !important;
  width: 550px;

  .title {
    padding: 10px 42px 10px 23px;
    background-color: $secondary_grey;
    color: $primary_link;

    h2 {
      font-size: 22px;
      font-weight: 500 !important;
      font-family: $Inter;
    }
  }
  .licence-expired-message {
    font-size: 14px;
    font-family: $Inter;
    color: $title_black;
    font-weight: 600;
    span {
      color: $primary_text;
    }
  }

  .license-expired-modal__title {
    position: relative;
  }
  .license-expired-modal__icon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -3px;
  }
}
