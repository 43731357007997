@import url("https://fonts.googleapis.com/css?family=Montserrat:300i,400,400i,500,500i,600,600i,700,700i,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
$Inter: "Inter", sans-serif;
$poppins: "Poppins", sans-serif;
$light_grey: #fafafa;
$secondary_grey: #ecf0f3;
$secondary_grey_inset: #a7a8a9;
$title_black: #2a2a2a;
$subtitle: #4b6e86;
$disabled_grey: #767676;
$disabled_grey--dark: #707070;
$dark_color: #0b2239;
$primary_text: #f95a3f;
$primary_link: #4b6e86;
$errorClr: #ff0018;
$white: #ffffff;
$black: #000000;
$editBtnClr: #4cb04e;
$deleteBtnClr: #ff2e43;
$commonLinkClr: #3987fd;
$aboutToRun: #fff0e0;
$available: #f2fff5;
$expired: #fde0e3;
$couldHaveTag: #6bd098;
$niceHaveTag: #fbc658;
$warningClr: #f9c83f;
$highlighted_text: #fff570;
$confirmationClr: #0e97ff;
$featured: #f9c83f;
$lightGreen: #4cb04e;
$sidenavRed: #ec1c24;
$sidenavBgRed: #ffe0e1;
$sidenavBorder: #857371;
$overlay: rgba(42, 42, 42, 0.5);
$fnt500: 500;
$fnt500: 500;
$fnt600: 600;
$fnt700: 700;
