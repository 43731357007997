@import "../../../scss/variable.scss";

.custom-snackbar-common {
  min-width: 30%;
  max-width: 60%;
}

.custom-snackbar-primary .MuiSnackbarContent-root {
  background-color: $editBtnClr;
}

.custom-snackbar-danger .MuiSnackbarContent-root {
  background-color: $deleteBtnClr;
}

.close-icon-snackbar {
  width: 14px;
  height: 14px;
}
