@import "../../../scss/variable.scss";

.forgot-password-card {
  position: relative !important;
  border-radius: 0px !important;
  width: 100% !important;
  height: calc(100vh - 5px) !important;
  margin: 2px 0px !important;
  right: 0 !important;
}

.forgot-password-image {
  width: 728px;
  height: 93vh;
  margin-top: 6vh;
  float: right;
  margin-right: 20px;
}

.card-subtitle {
  color: $primary_link;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  display: block;
  margin-bottom: 24px;
  font-family: $Inter;
}
