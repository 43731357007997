@import "./variable.scss";

.notification-tab {
  margin-top: 20px;

  .left-tab {
    border: 1.5px solid #dadada;
    height: 90vh;
    overflow-y: auto;

    .MuiTab-wrapper {
      align-items: flex-start;
      text-transform: capitalize;
      font-family: $Inter;
    }

    .tab-indicator {
      display: none;
    }

    .custom-tabs {
      border-bottom: none;
    }

    .notification-tab-label {
      background: $white;
      min-width: 100% !important;
      color: #000;
      position: relative;
    }

    .MuiTab-textColorInherit {
      opacity: 1;
    }

    .Mui-selected {
      background: $secondary_grey;
      color: $primary_text;

      &::before {
        position: absolute;
        width: 5px;
        height: calc(100% + 2px);
        left: -1px;
        top: -1px;
        content: "";
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: $primary_link;
      }
    }
  }

  .notification-content-box {
    font-family: $Inter;
    background: #fafafa;
    border: 2px solid #dadada;
    padding: 6px 15px;
    border-radius: 5px;
    width: 98%;
    margin-left: 2%;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 10px;

    .notification-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-size: 17px;
        margin-bottom: 0;
        font-weight: $fnt500;
        text-transform: capitalize;
        width: auto;

        a {
          color: $primary_text;
        }

        small {
          font-weight: $fnt500;
          font-size: 12px;
          color: $black;
        }
      }

      p {
        margin-bottom: 0;
        font-size: 13px;
        color: #000;

        img {
          width: 14px;
          transform: translateY(-1px);
        }
      }
    }

    .notification-body {
      p {
        margin-bottom: 3px;
        font-size: 14px;
        margin-top: 5px;
      }
    }

    .notification-indicator {
      position: absolute;
      width: 5px;
      height: calc(100% + 2px);
      left: -1px;
      top: -1px;
      content: "";
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
}

.notification-list-sort {
  width: 70px;
  border: 1px solid #707070;
  border-radius: 4px;
  padding: 6px;
  font-size: 1rem;
  align-items: center;
  display: inline-block;
  cursor: pointer;
  background: #efefef;

  img {
    width: 23px;
    height: 19px;
    margin-left: 2px;
  }
}
