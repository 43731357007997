@import "./variable.scss";
@import "./mixin.scss";

* {
  font-family: $Inter !important;
}

.highlighted-text__yellow {
  background: $highlighted_text;
}

.sidebar-anchor {
  color: inherit !important;
  width: 100%;
  display: inline-block;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.auth-container {
  padding: 0;
  margin: 0;
  background-color: $white;
  transition: 0.5s ease;

  .navbar-container {
    max-width: 275px;
    flex-basis: 275px;
    // animation : second 0.2s linear;
    transition: 0.5s ease;
  }

  @keyframes second {
    0% {
      max-width: 100px;
    }

    50% {
      max-width: 150px;
    }

    100% {
      max-width: 275px;
    }
  }

  .navbar-collapse {
    width: calc(100% - 40px);
    flex-basis: calc(100% - 40px);
    transition: 0.5s;
  }

  .component-container {
    max-width: calc(100% - 275px);
    flex-basis: calc(100% - 275px);
    position: fixed;
    right: 0;
    width: 100%;
    top: 0;
    transition: 0.5s;
  }

  .expand-component-container {
    max-width: calc(100% - 29px);
    flex-basis: calc(100% - 29px);
    position: fixed;
    right: 0;
    width: 100%;
    top: 0;
    transition: 0.5s;
  }

  .content {
    position: absolute;
    // overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(243, 248, 248, 0.34);
    width: 97%;
    max-height: 100vh;
    height: calc(100vh - 60px);
    top: 48px;
    left: 47px;
    transform-origin: left;
    @include firefox-scroll;
  }
}

.error-msg {
  font-size: 12px;
  margin: 5px 0;
  margin-left: 8px;
  display: inline-block;
  color: $errorClr !important;
  white-space: initial; //If error is too long, show it in the next line.
  text-transform: none;
}

.view-box {
  width: 100%;
  height: auto;
  // border: 1px solid #dadada;
  font-size: 14px;
  // font-family: $inter;
  font-weight: normal;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 20px !important;

  li {
    padding-bottom: 7px;
    padding-top: 7px;
    font-weight: 500;

    label {
      width: 50%;
      display: inline-block;
      color: $disabled_grey;
      margin-bottom: 0px !important;
    }

    span {
      width: 60%;
      display: inline-block;
      color: $title_black;
      overflow: hidden;
      text-overflow: ellipsis;
      // text-transform: capitalize;
      white-space: nowrap;

      p {
        margin: 0px;
        white-space: initial;
      }
    }

    span.name {
      color: rgba(59, 127, 171, 0.98) !important;
    }

    span.nameLink {
      // text-decoration: underline;
      cursor: pointer !important;
    }
    span.nameLink:hover {
      color: rgba(59, 127, 171, 0.98) !important;
      text-decoration: none;
    }
    span.namePointer {
      cursor: pointer;
    }
  }

  hr {
    height: 0.5px !important;
  }
}

.view-button {
  width: auto;
  height: 51px;
  background: $secondary_grey 0% 0% no-repeat padding-box !important;
  border-radius: 52px !important;
  color: $editBtnClr !important;
  font-size: 14px;
  font-family: $Inter !important;
  font-weight: normal;
  @include outset;
  padding-left: 30px !important;
  padding-right: 30px !important;

  .view-button-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

.view-button-delete {
  color: $deleteBtnClr !important;
}

.view-box-featured {
  border: 3px solid $featured;
  position: relative;

  &::after {
    position: absolute;
    background-color: #f9c83f;
    width: 58px;
    height: 50px;
    content: "";
    right: -1px;
    top: -1px;
    border-bottom-left-radius: 20px;
    background-image: url(../assets/icons/featured.svg);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 16px 8px;
  }
}

.add-screen-container {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 24px;

  .add__screen-subtitle {
    font-size: 10px;
    color: $title_black;
    font-family: $Inter;
    font-weight: normal;
    margin-bottom: 15px;
    display: inline-block;

    span {
      color: $deleteBtnClr;
    }
  }
}

.add-screen-formcontrol {
  width: 40%;
}

.mobile-field input::-webkit-outer-spin-button,
.mobile-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mobile-field input[type="number"] {
  -moz-appearance: textfield;
}

.new-input-add-screen {
  width: 100%;
  border-radius: 5px !important;
  // border:0.3px solid lightgray !important;
  font-size: 12px !important;
  // background-color: $white !important;

  input,
  textarea {
    border: 0px;
    border-bottom: 0px;
    padding: 18px;
    border-radius: 5px !important;
    background: #fafafa;
  }

  .MuiFormLabel-asterisk {
    color: $deleteBtnClr;
  }

  div {
    &:hover:before,
    &:focus:before,
    &:before,
    &:focus,
    &:after {
      border-bottom: 0px !important;
      border-color: transparent;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #707070 !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid $editBtnClr !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #707070;
  }
}
// .new-input-add-screen {
.new-input-add-screen > .MuiInputLabel-outlined {
  transform: translate(14px, 13px) scale(1) !important;
}
// }

.input-add-screen {
  width: 100%;
  border-radius: 3px !important;
  font-size: 16px !important;
  background-color: $white !important;

  input,
  textarea {
    border: 0px;
    border-bottom: 0px;
    padding: 18px 16px 18px 16px;
    // background: #fafafa;
  }

  .MuiFormLabel-asterisk {
    color: $deleteBtnClr;
  }

  div {
    &:hover:before,
    &:focus:before,
    &:before,
    &:focus,
    &:after {
      border-bottom: 0px !important;
      border-color: transparent;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #707070 !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid $editBtnClr !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #707070;
  }
}

.input-add-screen-small {
  width: 100%;
  border-radius: 3px !important;
  font-size: 16px !important;
  background-color: $white !important;

  input,
  textarea {
    border: 0px;
    border-bottom: 0px;
    padding: 11px 16px 11px 16px;
    // background: #fafafa;
  }

  .MuiFormLabel-asterisk {
    color: $deleteBtnClr;
  }

  div {
    &:hover:before,
    &:focus:before,
    &:before,
    &:focus,
    &:after {
      border-bottom: 0px !important;
      border-color: transparent;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #707070 !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid $editBtnClr !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #707070;
  }
}

.input-add-screen-datefilter-small {
  width: 100%;
  border-radius: 3px !important;
  font-size: 12px !important;
  background-color: $white !important;

  input,
  textarea {
    border: 0px;
    border-bottom: 0px;
    font-size: 15px !important;
    padding: 18px 0px 18px 5px;
    // background: #fafafa;
  }

  .MuiFormLabel-asterisk {
    color: $deleteBtnClr;
  }

  div {
    &:hover:before,
    &:focus:before,
    &:before,
    &:focus,
    &:after {
      border-bottom: 0px !important;
      border-color: transparent;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #707070 !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid $editBtnClr !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #707070;
  }
}

.circular-loader {
  width: 60px !important;
  height: 60px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 9999;
}

.circular-loader-color {
  color: $primary_text !important;
}

input.Mui-disabled {
  background-color: #ccc9c9 !important;
}

.MuiInputBase-root.Mui-disabled {
  background-color: #ccc9c9 !important;
}

button:focus {
  outline: none !important;
}

.add-btn {
  text-transform: capitalize !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  background: #fafafa;
}

.MuiSelect-select.Mui-disabled {
  background: #ccc9c9;
}

.MuiFormControl-marginNormal {
  margin-bottom: 0px !important;
}

.MuiButton-label {
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.PrivateSwitchBase-root-37 {
  padding: 12px 0px !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2) !important;
  border-top: none !important;
  border-radius: 5px !important;
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.MuiAccordion-root:before {
  background: none !important;
}

.MuiAvatar-colorDefault {
  background: none !important;
}

.MuiTableRow-root {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.forgot-password-btn-link:hover {
  background: none !important;
  color: $primary_text !important;
}

.MuiIconButton-root:hover {
  background: none !important;
}

.MuiCollapse-wrapperInner {
  background: $secondary_grey;
}

.footerPagination {
  margin: 0px 15px;
}
.show__option {
  margin-bottom: 25px;

  ul li p {
    display: none;
  }
}

.pagination {
  border-radius: 30px;
}

.pagination ul .page-item.active .page-link {
  border: 0;
  @include inset;
  font-weight: $fnt600;
  color: $primary_text;
  border-radius: 56% !important;
}

.pagination ul {
  @include outset;
}

.pagination ul .page-link {
  background: $secondary_grey;
  color: $title_black;
  padding: 9px 15px;
}

ul.pagination li:last-child a {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

ul.pagination li .page-link {
  border: none !important;
  font-weight: $fnt500;
  color: $title_black;
  font-size: 16px;
}

.pagination span {
  margin-left: 30px;
  margin-top: 15px;
  font-weight: $fnt500;
  color: $title_black;
  font-size: 16px;
}

.arrowPage {
  width: 12px;
}

.show__option ul {
  margin: 6px 0px 0px 0px;
  padding: 0;
}

.show__option ul li {
  list-style: none;
  display: inline-block;
  margin-left: 15px;
  color: #000;
  font-weight: $fnt500;
  font-size: 16px;
  padding: 2px 0px;
  font-family: $Inter;
}

.show__option ul li:first-child {
  margin-left: 10px;
}

.show__option ul li:nth-child(3) {
  width: 80px !important;
}

ul.pagination li:nth-child(3) {
  width: auto !important;
}

ul.pagination li:first-child {
  margin-left: 3px !important;
}

ul.pagination li:last-child {
  margin-right: 3px !important;
}

.pageNumber .MuiSelect-selectMenu {
  @include inset;
  border-radius: 20px;
}

.pageNumber {
  @include inset;
}

.pageNumber span {
  display: none;
}

.pageNumber .MuiFormControl-marginNormal {
  margin-top: 4px !important;
}

.pageNumber .MuiInputBase-root {
  background: $secondary_grey !important;
}

.pageNumber:focus {
  border-radius: 20px !important;
}

.show__option ul li .MuiFormControl-marginNormal {
  margin-top: 0px !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #f95a3f;
  border: none;
  border-bottom: 2px solid #f95a3f;
  font-weight: 500;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-top: none;
  border-right: none;
  border-left: none;
}

.nav-tabs .nav-link {
  color: $black;
}

.field-label {
  color: $title_black;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  font-size: 14px;
  font-family: $Inter;
  margin-top: 24px;
}

.primary-text {
  color: $primary_text !important;
}

.info-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

.primary-button {
  padding: 6px 6px !important;
  border-radius: 5px !important;
  background-color: $primary_text !important;
  color: $white !important;

  .primary-btn-icon {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}

.nameLink {
  color: $primary_text;
  cursor: pointer;
}

.MuiSelect-select:focus {
  border-radius: 20px !important;
}

.uppercase {
  text-transform: uppercase;
}

.add-btn-link {
  float: right;
  cursor: pointer;
  color: $primary_link;
  font-weight: $fnt500;
  font-size: 13px;
  margin-top: 5px;
  font-family: $Inter;
}

.add-btn-link img {
  width: 14px;
  margin-top: -2px;
}

a.add-btn-link {
  &:hover {
    color: $primary_link;
  }
}

.dash-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 10px;
  font-family: $Inter;

  .left-header,
  .right-header {
    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      list-style: none;
      margin: 0px 10px;
      font-size: 14px;
      font-weight: $fnt500;
    }
  }

  .left-header ul li {
    background: $secondary_grey;
    padding: 3px 10px;
    border-radius: 20px;
    text-transform: capitalize;
  }

  .left-header ul li.backslash {
    background: transparent;
    padding: 3px 0px;
    border-radius: 0px;
    margin: 0px;
    font-size: 18px;
  }

  .left-header ul li.active-breadcrumb {
    color: $commonLinkClr;
    cursor: pointer;
  }

  .left-header ul li img,
  .right-header ul li img {
    width: 16px;
    transform: translateY(-2px);
  }
}

.inActive-badge {
  background: $errorClr;
  color: $white;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 20px;
}

.warning-badge {
  background: $warningClr;
  color: $white;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 20px;
}

.active-badge {
  background: $editBtnClr;
  color: $white;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 20px;
}

.expired-badge {
  background: $expired;
  color: $white;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 20px;
}

.pt-0 .MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1) !important;
}

.pt-0 .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.pt-0 .MuiOutlinedInput-input {
  padding: 9px !important;
}
.cursor-none {
  cursor: not-allowed;
}
.opacity-low {
  opacity: 0.5;
}
@media only screen and (max-width: 1300px) {
  .photo-image {
    width: 95% !important;
    height: 160px !important;
    margin-left: 10px !important;
  }
  .left-margin img {
    margin-left: -15px !important;
  }
  .delivery-person__franchise-label {
    width: 33% !important;
  }
  .delivery-person__franchise-value {
    width: 57% !important;
  }
}
@media only screen and (max-width: 1200px) {
  .pt-0 .MuiOutlinedInput-input {
    padding: 9px !important;
  }

  .dash-header .left-header li,
  .dash-header .right-header li {
    font-size: 13px;
  }
}

.notification {
  right: 380px !important;
  position: relative !important;
}

.expand-component-container .notification {
  right: 205px !important;
}

.customNotification {
  box-shadow: 3px 3px 8px #666;
  border-radius: 5px;
  width: auto;
  background: #efefef;

  h1 {
    padding: 10px 10px;
    background: $dark_color;
    color: $white;
    font-size: 16px !important;
    font-weight: 400 !important;
    width: 100%;
    margin-bottom: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    small {
      color: #c4c4c4 !important;
      font-weight: 400 !important;
    }

    a {
      color: $white !important;
    }
  }

  p {
    font-size: 14px;
    padding: 5px;
    margin: 0px;
  }

  .notification__close {
    top: 6px !important;
    z-index: 9999;
    right: -84px;
    /* width: 100%; */
    border: 1px solid #fff;
    width: 25px;
    height: 25px;
  }

  .notification-content-box {
    padding: 0;
    border: none;
  }

  .notification-indicator {
    display: none;
  }

  .notification-body p {
    padding: 10px;
  }
}

.color-1 {
  background: $primary_link;
}

.color-2 {
  background: $editBtnClr;
}

.MuiTreeItem-root {
  max-height: 200px;
  overflow-y: scroll;
  padding: 0 !important;
  background: none !important;
}

.MuiTreeItem-root * {
  padding: 2px 0;
  background: none !important;
  overflow: hidden !important;
}

.error-color {
  color: $errorClr;
}

.customRadionBtns .MuiFormControl-marginNormal {
  margin-top: 0px !important;
}

.display-none {
  display: none !important;
}

.MuiMenu-list .MuiMenuItem-root {
  text-transform: capitalize !important;
}

.btn-class {
  display: flex !important;
}

.login-card .btn-class {
  display: block !important;
}

.disabled-bg-white {
  .Mui-disabled {
    background: #fafafa !important;
    color: rgba(0, 0, 0, 0.54) !important;
    opacity: 1 !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.pointer-events--disabled {
  pointer-events: none;
}
.cursor-unset {
  cursor: unset !important;
}

.font-serif {
  font-family: sans-serif !important;
}

.space-letter {
  letter-spacing: 1px;
}

.status-toggle .MuiFormControlLabel-root {
  align-items: flex-start !important;
}

.input-add-screen textarea {
  padding: 0 !important;
}

i {
  width: 1em;
}

svg {
  width: inherit;
  height: inherit;
}

//1px to 50px classes for margin
@for $i from 1 to 50 {
  .mt-#{$i}px {
    margin-top: #{$i}px;
  }
  .mr-#{$i}px {
    margin-right: #{$i}px;
  }
  .ml-#{$i}px {
    margin-left: #{$i}px !important;
  }
  .negative-top-margin-#{$i}px {
    margin-top: -#{$i}px;
  }
  .negative-top-#{$i}px {
    top: -#{$i}px;
    position: relative;
  }
}
@for $i from 1 to 100 {
  .wd-#{$i}-percent {
    width: calc(#{$i} * 1%);
  }
}
.text-muted {
  @include text-muted;
}

.text-capitalize {
  text-transform: capitalize;
}

@import "./variable.scss";

.photo-image {
  width: 95%;
  height: 200px;
  margin-left: 20px;
  border: 1px solid #dadada;
  border-radius: 5px;
  margin-top: 20px;
}

span.image_name {
  margin-left: 20px;
  color: #2a2a2a;
  font-weight: 500;
}

// Image uploader css.
.image-box {
  position: relative;
  width: 180px;

  .icon {
    position: absolute;
    right: 20px;
    top: 8px;
    width: 30px;
    transition: 0.5s;
    background: #fff;
    border-radius: 50px;
    cursor: pointer;
  }
}

.download-now-btn {
  color: $commonLinkClr !important;
  font-family: $Inter !important;
  font-size: 12px !important;
  font-weight: normal !important;
  margin-left: 20px !important;
  padding: 0px !important;

  img {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
}

a:hover {
  color: $primary-text;
}

.MuiAccordionSummary-root {
  transition: none !important;
}
// it is use to load alt images correctly on firefox
img:-moz-loading {
  visibility: hidden;
}

.remove-ellipses {
  white-space: initial !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.color--asterisk {
  color: $deleteBtnClr;
}

.btn-disabled {
  color: #707070 !important;
}

//For dom elements that are clickable and can redirect to a page.
.route-link {
  cursor: pointer;
  color: $primary-text;
}

.main-head {
  color: $title_black;
  font-size: 23px;
  font-family: $Inter;
  font-weight: 500;
  transform: translateY(4px);
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-unset {
  text-transform: unset;
}

.align-center {
  display: flex;
  align-items: center;
}

.dynamic_table_height {
  max-height: 70vh !important;
}

.custom-btn {
  color: $white !important;
  background: linear-gradient(
    269.86deg,
    #34576e 0.11%,
    rgba(59, 127, 171, 0.98) 99.88%
  );
  padding: 0.3rem 3rem !important;
  .add-icon {
    width: 16px;
    height: 16px;
    transform: translateY(1px);
    margin-right: 4px;
  }

  &:hover {
    background: linear-gradient(
      269.86deg,
      #5e87a3 0.11%,
      rgba(59, 127, 171, 0.98) 99.88%
    );
  }
}

.form-labels {
  font-size: 15px;
  color: $disabled_grey;
  font-weight: $fnt500;
  text-align: right;
  // width: 95%;
  position: relative;

  span {
    position: absolute;
    top: -4px;
    right: -8px;
    color: $errorClr;
    font-size: 13px;
  }
}

.view-button-save {
  color: $white !important;
  background: linear-gradient(
    269.86deg,
    #34576e 0.11%,
    rgba(59, 127, 171, 0.98) 99.88%
  );
  padding: 0.4rem 2rem !important;
  .add-icon {
    width: 16px;
    height: 16px;
    transform: translateY(1px);
    margin-right: 4px;
  }

  &:hover {
    background: linear-gradient(
      269.86deg,
      #5e87a3 0.11%,
      rgba(59, 127, 171, 0.98) 99.88%
    );
  }
}

.view-button-border-delete {
  color: darkblue !important;
  background: transparent !important;
  border: 1px solid darkblue !important;
  padding: 0.4rem 2rem !important;
  .add-icon {
    width: 16px;
    height: 16px;
    transform: translateY(1px);
    margin-right: 4px;
  }

  // &:hover {
  //   background: linear-gradient(269.86deg, #5e87a3 0.11%, rgba(59, 127, 171, 0.98) 99.88%);

  // }
}

.custom-label {
  margin-top: 25px;
}

.form-label {
  font-size: 16px;
  color: $disabled_grey;
  font-weight: $fnt500;
  text-align: right;
  // width: 95%;
  position: relative;

  span {
    position: absolute;
    top: -1px;
    right: -8px;
    color: $errorClr;
    font-size: 13px;
  }
}

.drawer-close-button {
  font-size: 30px;
  text-align: left;
  font-weight: 500;
  margin-left: 20px;
  width: 96%;
  margin-bottom: 20px;
  border: 0.3px solid grey !important;
  .operation-title {
    text-transform: capitalize;
  }
}

.cross-icon-drawer {
  border-radius: 5px !important;
  padding: 0px !important;
  float: right;
  // border: 0.5px solid rgba(128, 128, 128, 0.521) !important;
  margin-top: 5px !important;

  &:focus {
    outline: none;
    border: 0px;
  }

  img {
    padding: 3px;
    width: 30px;
    height: 25px;
    margin: 5px 0px;
    filter: invert(18%) sepia(0) saturate(548%) hue-rotate(100deg)
      brightness(21%) contrast(1%);
  }

  .MuiIconButton-label {
    background-color: white !important;
    border-radius: 5px !important;
    box-shadow: inset -1px 2px 3px rgba(0, 0, 0, 0.2);
  }
}
